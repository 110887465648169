import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function  CityViewModel()
{


    const schoolRepository=SchoolRepository();

    const [lstCities, setCities] = useState({
        data: []
    })

    const [lstDistricts, setDistricts] = useState({
        data: []

    })
       


    const [lstFilterCities, setFilterCities] = useState(
        []
    )

   
    
    const [validate, setValidate] = useState({
        city_id: 0,
        city_code: '',
        city_name: '',
        district_id: 0,
        district_name: '',
        updated_date: ''
    })

    const [showModal, setShowModal] = useState(false);

    const [city, setCity] = useState({
        city_id: 0,
        city_code: '',
        city_name: '',
        district_id: 0,
        district_name: '',
        updated_date: ''
    }) 

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredCitys = lstCities.data.filter((city: any) =>
            city.city_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterCities(filteredCitys);
        setSearchTerm(e.target.value);
    };

    const getCities = async () => {

        debugger;
        try {
            const data:any = await  schoolRepository.getCities();

            setCities({
                data
            })

            setFilterCities(data)
            debugger;
        }
        catch (error) {
            setCities({
                data: []
            })
        }
    }  


    const getCitysbyDistrict = async () => {  

        debugger;
        try {
            const data:any = await  schoolRepository.getCitysDistricts();

            setDistricts({
                data
            })
        }
        catch (error) {
            setDistricts({
                data: []
            })
        }
    }

    //pagination// 
    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredCitys = lstFilterCities.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterCities.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }


    useEffect(() => {

        getCities();
        getCitysbyDistrict();

    }, [alert]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setCity((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setCity((prev) => ({ ...prev, [name]: value }));
        }
    }

    const saveCity = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!city.city_code.trim()) {
            newValidate.city_code = "City code required"
        }

        if (!city.city_name.trim()) {
            newValidate.city_name = "City name required"
        }

        if (city.district_id === 0) {
            newValidate.district_name = "District required"
        }


        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                newValidate.city_code = "";
                const responseData:any = await   schoolRepository.saveCity(city);
              
                if (responseData[0].db_result > 0) {
                    cancel();

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }

                    getCities()
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const editCity = (e: any) => {
        debugger;
        setCity(e);
    }

    const deleteCitys = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData :any= await schoolRepository.deleteCitys({ 'city_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getCities();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const cancel = () => {
        debugger;

        setCity({
            city_id: 0,
            city_code: '',
            city_name: '',
            district_id: 0,
            district_name: '',
            updated_date: ''
        })

        setValidate({
            city_id: 0,
            city_code: '',
            city_name: '',
            district_id: 0,
            district_name: '',
            updated_date: ''
        })


    }

    const addCity = async (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
        setShowModal(true);
    }



    return{searchTerm,handleSearch,addCity,filteredCitys,editCity,deleteCitys,prePage,numbers,currentPage,changePage,nextPage,cancel,handleChange,validate,city,lstCities,saveCity,lstDistricts}
}