import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function Districtviewmodel()
{
 

    const schoolRepository=SchoolRepository();

    const [lstDistricts, setDistricts] = useState({
        data: []
    })

    const [lstFillterDistricts, setFillterDistricts] = useState(
        []

    ) 

    
    const [lstStates, setStates] = useState({
        data: []
    })

    const [showModal, setShowModal] = useState(false);

    const [district, setDistrict] = useState({
        district_id: 0,
        district_code: '',
        district_name: '',
        state_id: 0,
        state: '',
        updated_date: ''
    })

    const [validate, setValidate] = useState({
        district_id: 0,
        district_code: '',
        district_name: '',
        state_id: 0,
        state: '',
        updated_date: ''
    })

    const getDistricts = async () => {
        try { 
debugger;
            const data:any = await  schoolRepository.getDistricts();
          
            setDistricts({
                data
            })

            setFillterDistricts(data);
        }
        catch (error) {
            setDistricts({
                data: []
            })
        }
    }

    const getStates = async () => {
        try {

            const data:any = await  schoolRepository.getDistrictStates();
            setStates({
                data
            })
        }
        catch (error) {
            setStates({
                data: []
            })
        }
    }


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filterDistricts = lstDistricts.data.filter((district: any) =>
            district.district_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFillterDistricts(filterDistricts);
        setSearchTerm(e.target.value);
    };


    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filterDistricts = lstFillterDistricts.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFillterDistricts.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }

    useEffect(() => {
        getStates();
        getDistricts();
    }, [alert]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setDistrict((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setDistrict((prev) => ({ ...prev, [name]: value }));
        }
    }

    const editDistrict = (e: any) => {
        debugger;
        setDistrict(e);
    }

    const deleteDistrict = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
       
        if (willDelete.isConfirmed) { 
            debugger;
            const responseData :any= await schoolRepository.deleteDistrict({'district_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getDistricts();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const addState = async (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
        setShowModal(true);
    }

    const saveDistrict = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!district.district_name.trim()) {
            newValidate.district_name = "District name required"
        }

        if (!district.district_code.trim()) {
            newValidate.district_code = "District code required"
        }

        if (district.state_id === 0) {
            debugger;
            newValidate.state = "State required";
        } 
        else{}

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger; 

                const responseData:any = await   schoolRepository.saveDistricts(district);
                   
               
                if (responseData[0].db_result > 0) {
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }
                    getDistricts();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const cancel = () => {
        debugger;
        setDistrict({
            district_id: 0,
            district_code: '',
            district_name: '',
            state_id: 0,
            state: '',
            updated_date: ''
        })

        setValidate({
            district_id: 0,
            district_code: '',
            district_name: '',
            state_id: 0,
            state: '',
            updated_date: ''
        })


    }




return{searchTerm,handleSearch,addState,filterDistricts,editDistrict,deleteDistrict,prePage,numbers,currentPage,changePage,nextPage,saveDistrict,cancel,district,handleChange,validate,lstStates,getDistricts}




}