import React from "react";


function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="container-fluid text-end">
                <div className="row">
                    <div className="mb-2 col-md-12">
                       
                        {currentYear} © Education Today India
                    </div>
                </div>
            </div>
        </footer>
    );
}


export default Footer;