import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useHistory } from "react-router-dom";
import { ActionType } from "../../store/Page.Actions";
import { useDispatch } from "react-redux";
import 'react-quill/dist/quill.snow.css'; 
export function Schoolviewmodel()
{
    const navigate = useHistory();
    const schoolRepository=SchoolRepository();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
const [school_id,setSchoolId]=useState(0);

    const [schoolInfo, setSchoolInfo] = useState({
        title: 'schoolInfo',
        school_id: 0,
        udise_code: '',
        school_name: '',
        instruction_medium: '',
        school_type: '',
        classes: '',
        year_of_establishment: 0,
        school_area: '',
        school_shifted_to_new_place: '',
        management: '',
        building: '',
        pre_primary_sectin_avilable: '',
        board_for_class_10th: '',
        status: '',
        image:'',
        address:''
    })

    const [lstFillterschools, setFillterSchools] = useState(
        []

    )

    const [schoolInfoSubmit, setSchoolInfoSubmit] = useState(false);
    const [activeTab, setActiveTab] = useState('schoolInfo');
    const [activeMainTab, setActiveMainTab] = useState('All');
    const [addressInfo, setAddressInfo] = useState({
        title: 'addressInfo',
        state_id: 0,
        district_id: 0,
        city_id: 0,
        cluster_id: 0,
        location: '',
        contact_no: '',
        email: '',
        password: '',
        repassword: '',
        address:''
    })
    const [addressInfoSubmit, setaddressInfoSubmit] = useState(false);

    const [resourceInfo, setResourceInfo] = useState({
        title: 'resourceInfo',
        head_teacher: 0,
        total_teachers: 0,
        pre_primary_teachers: 0,
        female_teachers: 0,
        male_teachers: 0,
        contract_teachers: 0,
        primary_teachers: 0,
        middle_school_teachers: 0,
        high_school_teachers: 0
    })
    const [resourceInfoSubmit, setresourceInfoSubmit] = useState(false);


 const   statusTabClick=(activeTab:any)=>
    {
    let lstFillterschoolsData=    searchResult(searchTerm);
       // setSearchTerm('');
        debugger;
        setActiveMainTab(activeTab);
if(activeTab!='Verified')
{    
    if(activeTab=='All')
    {
        setFillterSchools(lstFillterschoolsData);

    }
    else{
    let filterListSchools = lstFillterschoolsData.filter((school: any) =>
        school.approved_status  ==  (activeTab=='Pendings'?'':activeTab)


    );
    setFillterSchools(filterListSchools);

    }
    
}
else{
    let filterListSchools = lstFillterschoolsData.filter((school: any) =>
    school.is_verified ==activeTab


);
setFillterSchools(filterListSchools);

}

setCurrentPage(1);


    }

    const [facilityInfo, setFacilityInfo] = useState({
        title: 'facilityInfo',
        class_rooms: 0,
        boys_toilet: 0,
        girls_toilet: 0,
        mid_day_meal: '',
        is_school_residential: '',
        residential_type: '',
        electricity: '',
        wall: '',
        library: '',
        playground: '',
        books_in_library: 0,
        drinking_water: '',
        ramps_for_disable: '',
        canteen: '',
        transport_facility: '',
        computer_aided_learning: '',
        computers: 0,
        updated_date: ''
    })
    const [facilityInfoSubmit, setfacilityInfoSubmit] = useState(false);

    const [aboutus, setAboutUs] = useState({
        about: '',
        school_id: 0,
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, title: string) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

            if (title == 'schoolInfo') {
                debugger;
                setSchoolInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'addressInfo') {
                debugger;
                setAddressInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'resourceInfo') {
                debugger;
                setResourceInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'facilityInfo') {
                debugger;
                setFacilityInfo((prev) => ({ ...prev, [name]: value }));
            }


        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            if (title == 'schoolInfo') {
                debugger;
                setSchoolInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'addressInfo') {
                debugger;
                setAddressInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'resourceInfo') {
                debugger;
                setResourceInfo((prev) => ({ ...prev, [name]: value }));
            }

            if (title == 'facilityInfo') {
                debugger;
                setFacilityInfo((prev) => ({ ...prev, [name]: value }));
            }
        }


    }

    const validateSchoolInfo = () => {
        debugger;
        setSchoolInfoSubmit(true);
        // setActiveTab('addressInfo');
        if (schoolInfo.udise_code != '' && schoolInfo.school_name != '' && schoolInfo.instruction_medium != '' && schoolInfo.school_type != '' && schoolInfo.classes != '' && schoolInfo.school_area != '' && schoolInfo.board_for_class_10th != '') {
            setActiveTab('addressInfo');
        }
    }

    const back = (e: string) => {
        debugger;
        setActiveTab(e);
    }

    const validateAddressInfo = () => {
        debugger;
        setaddressInfoSubmit(true);
        // setActiveTab('resourceInfo')
        if (addressInfo.state_id != 0 && addressInfo.address !=''&& addressInfo.district_id != 0 && addressInfo.city_id != 0 && addressInfo.cluster_id != 0 && addressInfo.contact_no != '' && addressInfo.email != '' && addressInfo.password != '' && addressInfo.repassword != '' && addressInfo.password == addressInfo.repassword
        && (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(addressInfo.email)
        
        ) {
            setActiveTab('resourceInfo')
        }
    }

    const validateResourceInfo = () => {
        debugger;
        setresourceInfoSubmit(true);
        setActiveTab('facilityInfo')
    }

    // const validateFacilityInfo = () => {
    //     debugger;
    //     setfacilityInfoSubmit(true);
    // }

    const handleSelectChange = (selectedOption: any | null) => {
        debugger;
        setSchoolInfo(prevSchoolInfo => ({
            ...prevSchoolInfo,
            classes: selectedOption ? selectedOption.value : '',
        }));
    };

    const handleStateChange = (selectedOption: any | null) => {
        debugger;
        setAddressInfo(preAddressInfo => ({
            ...preAddressInfo,
            state_id: selectedOption ? selectedOption.value : '',
        }));
        if (selectedOption.value > 0) {
            debugger;
            getDistrictByState(selectedOption.value);
        }
    };

    const handleDistrictChange = (selectedOption: any | null) => {
        debugger;
        setAddressInfo(preAddressInfo => ({
            ...preAddressInfo,
            district_id: selectedOption ? selectedOption.value : '',
        }));
        getCityByDistrict(selectedOption.value);
    };

    const handleCityChange = (selectedOption: any | null) => {
        debugger;
        setAddressInfo(preAddressInfo => ({
            ...preAddressInfo,
            city_id: selectedOption ? selectedOption.value : '',
        }));
        getClusterByCity(selectedOption.value);
    };

    const handleClusterChange = (selectedOption: any | null) => {
        debugger;
        setAddressInfo(preAddressInfo => ({
            ...preAddressInfo,
            cluster_id: selectedOption ? selectedOption.value : '',
        }));
        // getClusterByCity(selectedOption.value);
    };

    const saveSchool = async ( ) => {
        debugger;
        try {
            debugger; 

            const responseData:any = await   schoolRepository.saveSchool( schoolInfo,addressInfo,resourceInfo,facilityInfo);
         
            if (responseData[0].db_result > 0) {
                cancel();
                const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                if (buttonElement) {
                    buttonElement.click();
                }

                if (schoolInfo.school_id > 0) {
                    Swal.fire("Record updated successfully", "Record updated successfully", "success");
                } else {
                    Swal.fire("Record saved successfully", "Record saved successfully", "success");
                }
                getSchools();
            } else {
                Swal.fire("No changes made", "No changes made", "warning");
            }

        } catch (error) {
        }
    }

    const cancel = () => {
        debugger;
        setSchoolInfo({
            title: 'schoolInfo',
            school_id: 0,
            udise_code: '',
            school_name: '',
            instruction_medium: '',
            school_type: '',
            classes: '',
            year_of_establishment: 0,
            school_area: '',
            school_shifted_to_new_place: '',
            management: '',
            building: '',
            pre_primary_sectin_avilable: '',
            board_for_class_10th: '',
            status: '',
            image:'',
            address:''
        })

        setAddressInfo({
            title: 'addressInfo',
            state_id: 0,
            district_id: 0,
            city_id: 0,
            cluster_id: 0,
            location: '',
            contact_no: '',
            email: '',
            password: '',
            repassword: '',
            address:''
        })

        setResourceInfo({
            title: 'resourceInfo',
            head_teacher: 0,
            total_teachers: 0,
            pre_primary_teachers: 0,
            female_teachers: 0,
            male_teachers: 0,
            contract_teachers: 0,
            primary_teachers: 0,
            middle_school_teachers: 0,
            high_school_teachers: 0
        })

        setFacilityInfo({
            title: 'facilityInfo',
            class_rooms: 0,
            boys_toilet: 0,
            girls_toilet: 0,
            mid_day_meal: '',
            is_school_residential: '',
            residential_type: '',
            electricity: '',
            wall: '',
            library: '',
            playground: '',
            books_in_library: 0,
            drinking_water: '',
            ramps_for_disable: '',
            canteen: '',
            transport_facility: '',
            computer_aided_learning: '',
            computers: 0,
            updated_date: ''
        })

        setSchoolInfoSubmit(false);
        setActiveTab('schoolInfo');
    }

    const schoolClasses: any = [
        { value: 'Pre-Primary Only', label: 'Pre-Primary Only' },
        { value: 'Pre-Primary to Primary', label: 'Pre-Primary to Primary' },
        { value: 'Pre-Primary To 4', label: 'Pre-Primary To 4' },
        { value: '1 To 4', label: '1 To 4' },
        { value: 'Pre-Primary To 7', label: 'Pre-Primary To 7' },
        { value: 'Primary To 7', label: 'Primary To 7' },
        { value: '1 To 7', label: '1 To 7' },
        { value: 'Pre-Primary To 10', label: 'Pre-Primary To 10' },
        { value: 'Primary To 10', label: 'Primary To 10' },
        { value: '1 To 10', label: '1 To 10' },
        { value: 'Pre-Primary To 12', label: 'Pre-Primary To 12' },
        { value: 'Primary To 12', label: 'Primary To 12' },
        { value: '1 To 12', label: '1 To 12' },
        { value: '8 To 10', label: '8 To 10' },
        { value: '8 To 12', label: '8 To 12' },
    ];

    // States list
    const [lstStates, setStates] = useState<{ label: string; value: string }[] | []>([]);
    // Districts list
    const [lstDistricts, setDistricts] = useState<{ label: string; value: string }[] | []>([]);
    // Cities list
    const [lstCities, setCities] = useState<{ label: string; value: string }[] | []>([]);
    // Cluster list
    const [lstCluster, setCluster] = useState<{ label: string; value: string }[] | []>([]);
    // schools list
    const [lstSchools, setSchools] = useState({
        data: []
    })

    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const handleProcedureContentChange = (content: any) => {
        debugger;
        aboutus.about = content;
        setAboutUs(aboutus);

    };

    useEffect(() => {
        getStates();
        getSchools();
    }, [alert]);

    const getStates = async () => {
        try { 
            const data:any = await  schoolRepository.getschoolStates();
          
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.state_name,
                value: item.state_id.toString(),
            }));
            setStates(options)
        }
        catch (error) {
            console.error('Error fetching all options:', error);
        }
    } 



    const getSchools = async () => {
        try { 
            const data:any = await schoolRepository.getSchools()
           
            await   setSchools({
                data
            })

            await  setFillterSchools(data);
        //  /   await statusTabClick('Pendings');
        }
        catch (error) {
            setSchools({
                data: []
            })
        }
    }

    const getDistrictByState = async (e: string) => {
        try { 
            const data:any = await  schoolRepository.getDistrictByState({'state_id': e });
         
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.district_name,
                value: item.district_id.toString(),
            }));
            setDistricts(options)
        }
        catch (error) {
            console.error('Error fetching all options:', error);
        }
    }

    const getCityByDistrict = async (e: string) => {
        try {
            debugger;
            const data:any = await  schoolRepository.getCityByDistrict({ 'district_id': e });
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.city_name,
                value: item.city_id.toString(),
            }));
            setCities(options)
        }
        catch (error) {
            console.error('Error fetching all options:', error);
        }
    }
   
    const getClusterByCity = async (e: string) => {
        debugger;
        try {
            const data:any = await  schoolRepository.getClusterByCity( { 'city_id': e });
            debugger;
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.cluster_name,
                value: item.cluster_id.toString(),
            }));
            debugger;
            setCluster(options)
        }
        catch (error) {
            console.error('Error fetching all options:', error);
        }
    }

    const loadSchoolClasses = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterSchoolClasses(inputValue);
        callback(filteredOptions);
    };

    const filterSchoolClasses = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return schoolClasses.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return schoolClasses;
        }
    };

    const loadState = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterState(inputValue);
        callback(filteredOptions);
    };

    const filterState = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstStates.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstStates;
        }
    };

    const loadDistrict = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterDistrict(inputValue);
        callback(filteredOptions);
    };

    const filterDistrict = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstDistricts.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstDistricts;
        }
    };

    const loadCity = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterCity(inputValue);
        callback(filteredOptions);
    };

    const filterCity = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstCities.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstCities;
        }
    };

    const loadCluster = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterCluster(inputValue);
        callback(filteredOptions);
    };

    const filterCluster = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstCluster.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstCluster;
        }
    };

    const deleteSchool = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        
        if (willDelete.isConfirmed) {
            debugger; 
            const responseData :any= await schoolRepository.deleteSchool({ 'school_id': e });
          
            if (responseData[0].db_result > 0) {
                Swal.fire("Record Deleted successfully", "", "success");
                getSchools();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    } 

    

    const editSchool = (e: any) => {
        debugger;
        // setFacilityInfo({...facilityInfo,  ...e,});

        setSchoolInfo({
            title: 'schoolInfo',
            school_id: e.school_id,
            udise_code: e.udise_code,
            school_name: e.school_name,
            instruction_medium: e.instruction_medium,
            school_type: e.school_type,
            classes: e.classes,
            year_of_establishment: e.year_of_establishment,
            school_area: e.school_area,
            school_shifted_to_new_place: e.school_shifted_to_new_place,
            management: e.management,
            building: e.building,
            pre_primary_sectin_avilable: e.pre_primary_sectin_avilable,
            board_for_class_10th: e.board_for_class_10th,
            status: e.status,
            image:e.image,
            address:e.address
        })

        setAddressInfo({
            title: 'addressInfo',
            state_id: e.state_id,
            district_id: e.district_id,
            city_id: e.city_id,
            cluster_id: e.cluster_id,
            location: e.location,
            contact_no: e.contact_no,
            email: e.email,
            password: e.password,
            repassword: e.password,
            address: e.address
        });

        setResourceInfo({
            title: 'resourceInfo',
            head_teacher: e.head_teacher,
            total_teachers: e.total_teachers,
            pre_primary_teachers: e.pre_primary_teachers,
            female_teachers: e.female_teachers,
            male_teachers: e.male_teachers,
            contract_teachers: e.contract_teachers,
            primary_teachers: e.primary_teachers,
            middle_school_teachers: e.middle_school_teachers,
            high_school_teachers: e.high_school_teachers,
        });

        setFacilityInfo({
            title: 'facilityInfo',
            class_rooms: e.class_rooms,
            boys_toilet: e.boys_toilet,
            girls_toilet: e.girls_toilet,
            mid_day_meal: e.mid_day_meal,
            is_school_residential: e.is_school_residential,
            residential_type: e.residential_type,
            electricity: e.electricity,
            wall: e.wall,
            library: e.library,
            playground: e.playground,
            books_in_library: e.books_in_library,
            drinking_water: e.drinking_water,
            ramps_for_disable: e.ramps_for_disable,
            canteen: e.canteen,
            transport_facility: e.transport_facility,
            computer_aided_learning: e.computer_aided_learning,
            computers: e.computers,
            updated_date: e.updated_date,
        });

        getDistrictByState(e.state_id);
        getCityByDistrict(e.district_id);
        getClusterByCity(e.city_id);
    }

    const aboutUs = (e: any) => {
        debugger;
        aboutus.school_id = e.school_id;
        aboutus.about = e.about;
        // setAboutUs(aboutus); 
        setAboutUs({ ...aboutus, about: aboutus.about });
    }

    const saveAboutus = async () => {
        debugger;
        const responseData:any = await   schoolRepository.saveAboutus(aboutUs);

        if (responseData[0].db_result > 0) {
            setAboutUs({
                school_id: 0,
                about: ''
            })

            const buttonElement = document.getElementById('aboutModal') as HTMLButtonElement | null;
            if (buttonElement) {
                buttonElement.click();
            }

            Swal.fire("Success", "About us updated successfully", "success");
            getSchools();
        }
    }

const approveWindowClose=()=>
{

    // dispatch({
    //     type: ActionType.STORE,
    //     payload: {
    //         storeData:{selected_school_id:0},
    //         viewName:'selectedSchool',
    //     },
    //   });

}
 
const btnVerifyClick=async (school_id:any)=>
{
    debugger;
    Swal.fire({
        title: 'Is School Verified  ?',
        text: "This action cannot be undone!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // User clicked "Yes, continue!" button
          const responseData:any = await  schoolRepository.schoolVerifiedStatusUpdate({school_id:school_id});

          if (responseData[0].db_result > 0) {
            Swal.fire("Verified successfully", "Verified successfully", "success").then((action)=>
            {
                if (action.isConfirmed) {
                window.location.reload();
                }
            })
             ;
        
          }else{

          }


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked the cancel button
          Swal.fire('Cancelled', 'Your action has been cancelled.', 'info');
        }
      });

   

}

const btnApproveClick=(school_id:any)=>
{

    setSchoolId(school_id);
    // dispatch({
    //     type: ActionType.STORE,
    //     payload: {
    //         storeData:{selected_school_id:school_id},
    //         viewName:'selectedSchool',
    //     },
    //   });
    //navigate.push('/schoolapproval');
}

    const getBadgeClass = (status: 'Active' | 'Blocked' | 'Deleted' | 'Approved' | 'Rejected'): string => {
        switch (status) {
            case 'Active':
                return 'badge-success-lighten';

                case 'Approved':
                    return 'badge-success-lighten';

                    case 'Rejected':
                        return 'badge-warning-lighten';

            case 'Blocked':
                return 'badge-warning-lighten';
            case 'Deleted':
                return 'badge-danger-lighten';
            default:
                return '';
        }
    };


    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e: any) => {
        debugger;

        searchResult(e.target.value)

        // let filterListSchools = lstSchools.data.filter((school: any) =>
        //     school.school_name.toLowerCase().includes(e.target.value.toLowerCase())
        // );

        // setFillterSchools(filterListSchools);
        // setSearchTerm(e.target.value);
    };
function searchResult(searchData:any)
{
debugger;
    let filterListSchools = lstSchools.data.filter((school: any) =>
    school.school_name.toLowerCase().includes(searchData.toLowerCase())
);

setFillterSchools(filterListSchools);
setSearchTerm(searchData);
return  filterListSchools;
}
    const [currentPage, setCurrentPage] = useState(1);
   
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filterListSchools = lstFillterschools.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFillterschools.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }


    // Popup static width  
    const popupWidth = {
        maxWidth: '1300px'
    } 

    
    return{btnVerifyClick,statusTabClick,activeMainTab,school_id,approveWindowClose,btnApproveClick,searchTerm,handleSearch,filterListSchools,getBadgeClass,aboutUs,editSchool,deleteSchool,prePage,numbers,currentPage,changePage,nextPage,popupWidth,cancel,activeTab,schoolInfo,handleChange,schoolInfoSubmit,schoolClasses,handleSelectChange,loadSchoolClasses,validateSchoolInfo,lstStates,handleStateChange,addressInfo,loadState,addressInfoSubmit,lstDistricts,loadDistrict,lstCities,loadCity,lstCluster,loadCluster,handleDistrictChange,handleCityChange,handleClusterChange,validateAddressInfo,back,resourceInfo,validateResourceInfo,facilityInfo,saveSchool,modules,formats,handleProcedureContentChange,aboutus,saveAboutus}
    
}

 