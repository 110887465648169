import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// /import Login from './components/login';
import { Provider } from 'react-redux';
// import ChangePassword from './master-components/changepassword';
import { persistor, store } from './store/Page.Reducer';
import { PersistGate } from 'redux-persist/integration/react';
 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isLoggedIn = true;

root.render(
  <React.StrictMode>
  <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
    
     <App />
   
     </PersistGate>
     </Provider>
   </React.StrictMode>
  // <React.StrictMode>
  //   {isLoggedIn ? <App /> : <Login/>}
  //   {/* <Login/> */}
  //   {/* <ChangePassword/> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
