import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function UsersviewModel() {

    const schoolRepository = SchoolRepository();


    
    const [lstUsers, setUsers] = useState({
        data: []
    })

    const [listFillterUsers, setFilterlstUsers] = useState(
        []

    )

    const getLoginUsers = async () => {
        try {
debugger;
            const data: any = await schoolRepository.getLoginUsers();
            setUsers({
                data
            })
            setFilterlstUsers(data);
        }
        catch (error) {
            setUsers({
                data: []
            })
        }
    }

    useEffect(() => {
        getLoginUsers();
    }, []);


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredListusers = lstUsers.data.filter((users: any) =>
            users.full_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterlstUsers(filteredListusers);
        setSearchTerm(e.target.value);
    };


    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredListusers = listFillterUsers.slice(firstIndex, lastIndex);
    const npage = Math.ceil(listFillterUsers.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }




    const [users, setUserdata] = useState({
        user_id: 0,
        full_name: '',
        code: 0,
        contact_no: 0,
        email: '',
        password: '',
        role: '',
        report_to: '',
        address: '',
        updated_date: ''
    })

    const [validate, setValidate] = useState({
        user_id: 0,
        full_name: '',
        // code: '',
        contact_no: "",
        email: '',
        password: '',
        role: '',
        report_to: '',
        address: '',
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setUserdata((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setUserdata((prev) => ({ ...prev, [name]: value }));
        }
    }

    const saveUser = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };
        if (!users.full_name.trim()) {
            debugger;
            newValidate.full_name = "Name required"; 
            setValidate(newValidate);
        }

        if (users.contact_no == 0) {
            newValidate.contact_no = "Contact no required"; 
            setValidate(newValidate);
        }

        if (!users.email.trim()) {
            newValidate.email = "Email is required";
            setValidate(newValidate);
        } else if (!/\S+@\S+\.\S+/.test(users.email)) {
            newValidate.email = "Email is not valid!" 
           setValidate(newValidate);
        }

        if (!users.password.trim()) {
            newValidate.password = "Password required"; 
            setValidate(newValidate);
        }

        if (!users.role.trim()) {
            newValidate.role = "Role required"; 
            setValidate(newValidate);
        }

        if (typeof users.report_to === 'string' && !users.report_to.trim()) {
            newValidate.report_to = "Report to required"; 
            setValidate(newValidate);
        }

        if (!users.address.trim()) {
            newValidate.address = "Address required"; 
            setValidate(newValidate);
        }
         else{ 
            const newValidate = { ...validate };

            newValidate.full_name ="";
            newValidate.contact_no ="";
            newValidate.email ="";
            newValidate.password ="";
            newValidate.role="";
            newValidate.report_to ="";
            newValidate.address = "";
          

            setValidate(newValidate);
      

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                const responseData: any = await schoolRepository.saveUser(users);

                if (responseData[0].db_result > 0) {
                    debugger;
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }
                    getLoginUsers();
                }

                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
                console.log('Error making POST request:', error)
            }
        } 
   
    }
    }

    const deleteUser = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
          
                const responseData: any = await schoolRepository.deleteloginUser({'user_id': e });
               
                if (responseData[0].db_result > 0) {
                    debugger;
                   Swal.fire("Record Deleted successfully", "", "success");
                    getLoginUsers();
                } 
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }
//}

    const addUser = (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
    }

    const editUser = (e: any) => {
        debugger;
        setUserdata(e);
    }

    const generatePassword = () => {
        let password = Math.floor(10000 + Math.random() * 90000);
        setUserdata((prevUserData) => ({
            ...prevUserData,
            password: password.toString(),
        }))
    }

    const cancel = () => {

        setUserdata({
            user_id: 0,
            full_name: '',
            code: 0,
            contact_no: 0,
            email: '',
            password: '',
            role: '',
            report_to: '',
            address: '',
            updated_date: ''
        });
        setValidate({
            user_id: 0,
            full_name: '',
            // code: '',
            contact_no: "",
            email: '',
            password: '',
            role: '',
            report_to: '',
            address: ''
        });
    }




    return { searchTerm, handleSearch, addUser, filteredListusers, editUser, deleteUser, prePage, numbers, currentPage, changePage, nextPage, saveUser, cancel, users, validate, generatePassword, handleChange }
}