import { SchoolApproveviewModel } from "./SchoolApproveviewModel";




const SchoolApprove:React.FC<{school_id:number}>=({school_id})=>
{

    const schoolApproveViewModel = SchoolApproveviewModel(school_id)
return(
    
<div className="container">
    <div className="row">
        <div className="col-md-12">
    <div className="col-md-12">
    <div className="card">
    <div className="card-body">
        <div className="row">
        <div className="col-md-2">
            <img
            
            
            src={`${schoolApproveViewModel.imagePath}${schoolApproveViewModel.schoolApprove.image}.webp?${new Date().getTime()}`} 
            // src="https://images.unsplash.com/photo-1524069290683-0457abfe42c3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2Nob29sJTIwc3R1ZGVudHxlbnwwfHwwfHx8MA%3D%3D"
            
            className="img-class" alt="" />

            {/* <a className="btn btn-danger mb-2 mt-3 accept" data-bs-toggle="modal" data-bs-target="#user-modal"> Accept</a>
            <a className="btn btn-danger mb-2 mt-3 ms-2 reject" data-bs-toggle="modal" data-bs-target="#user-modal"> Reject</a> */}
        </div>
        <div className="col-md-10">
            {/* <h3 className="text-center">SCHOOL INFORMATION/DETAILS</h3> */}
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14 mb-2"  >UDISE Code</label><br></br>
                  
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.udise_code!=''?schoolApproveViewModel.schoolApprove.udise_code:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">School Name</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.school_name!=''?schoolApproveViewModel.schoolApprove.school_name:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Instruction Medium</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.instruction_medium!=''?schoolApproveViewModel.schoolApprove.instruction_medium:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14 mb-2">School Type</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.school_type!=''?schoolApproveViewModel.schoolApprove.school_type:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Classes </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.classes!=''?schoolApproveViewModel.schoolApprove.classes:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Year of Establishment</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.year_of_establishment!= 0?schoolApproveViewModel.schoolApprove.year_of_establishment:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14 ">School Area</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.school_area!= ""?schoolApproveViewModel.schoolApprove.school_area:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14 ">School Shifted to New Place</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.school_shifted_to_new_place!= ""?schoolApproveViewModel.schoolApprove.school_shifted_to_new_place:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Management</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.management!= ""?schoolApproveViewModel.schoolApprove.management:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Building</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.building!= ""?schoolApproveViewModel.schoolApprove.building:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Pre Primary Section Available</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.pre_primary_sectin_avilable!= ""?schoolApproveViewModel.schoolApprove.pre_primary_sectin_avilable:'-'}</p>
                </div>
                <div className="col-md-4">
                    <label htmlFor="" className="fw-bold fs-14">Board for Class 10th</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.board_for_class_10th!= ""?schoolApproveViewModel.schoolApprove.board_for_class_10th:'-'}</p>
                </div>
                
            </div>
            </div>
            <div className="col-md-12">
                <div className="row">
                <div className="col-md-12 mb-2">
                <h4 className="text-center title-color">ADDRESS /CONTACT INFORMATION</h4>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 mb-2">State </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.state_name!= ""?schoolApproveViewModel.schoolApprove.state_name:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Districts </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.district_name!= ""?schoolApproveViewModel.schoolApprove.district_name:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">City(Blocks)</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.city_name!= ""?schoolApproveViewModel.schoolApprove.city_name:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Cluster </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.cluster_name!= ""?schoolApproveViewModel.schoolApprove.cluster_name:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Location </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.location!= ""?schoolApproveViewModel.schoolApprove.location:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Contact No</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.contact_no!= ""?schoolApproveViewModel.schoolApprove.contact_no:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Address</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.address!= ""?schoolApproveViewModel.schoolApprove.address:'-'}</p>
                </div>
                
                </div>
            </div>

            <div className="col-md-12">
                <div className="row">
                <div className="col-md-12 mb-2 mt-3">
                <h4 className="text-center title-color">RESOURCE DETAILS</h4>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 mb-2">Head Teacher </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.head_teacher!= 0?schoolApproveViewModel.schoolApprove.head_teacher:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Total Teachers </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.total_teachers!= 0?schoolApproveViewModel.schoolApprove.total_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Pre Primary Teachers</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.pre_primary_teachers!= 0?schoolApproveViewModel.schoolApprove.pre_primary_teachers:'-'}</p>
                </div>
             
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Primary Teachers </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.primary_teachers!= 0?schoolApproveViewModel.schoolApprove.primary_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Middle School Teachers</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.middle_school_teachers!= 0?schoolApproveViewModel.schoolApprove.middle_school_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">High School Teachers</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.high_school_teachers!= 0?schoolApproveViewModel.schoolApprove.high_school_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Number of Female Teacher</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.female_teachers!= 0?schoolApproveViewModel.schoolApprove.female_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Number of Male Teacher</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.male_teachers!= 0?schoolApproveViewModel.schoolApprove.male_teachers:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Number of Contract Teachers</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.contract_teachers!= 0?schoolApproveViewModel.schoolApprove.contract_teachers:'-'}</p>
                </div>
                
                </div>
            </div>
            <div className="col-md-12">
                <div className="row">
                <div className="col-md-12 mb-2 mt-2">
                <h4 className="text-center title-color">FACILITY DETAILS</h4>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Total number of Class Rooms </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.class_rooms!= 0?schoolApproveViewModel.schoolApprove.class_rooms:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Number of Boys Toilet </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.boys_toilet!= 0?schoolApproveViewModel.schoolApprove.boys_toilet:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Number of Girls Toilet</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.girls_toilet!= 0?schoolApproveViewModel.schoolApprove.girls_toilet:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Mid-day Meal Status / Info </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.mid_day_meal!= ""?schoolApproveViewModel.schoolApprove.mid_day_meal:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Is School Residential </label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.is_school_residential!= ""?schoolApproveViewModel.schoolApprove.is_school_residential:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14">Residential Type</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.residential_type!= ""?schoolApproveViewModel.schoolApprove.residential_type:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Electricity</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.electricity!= ""?schoolApproveViewModel.schoolApprove.electricity:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Wall</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.wall!= ""?schoolApproveViewModel.schoolApprove.wall:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Library</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.library!= ""?schoolApproveViewModel.schoolApprove.library:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Playground</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.playground!= ""?schoolApproveViewModel.schoolApprove.playground:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Total Books in Library</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.books_in_library!= 0?schoolApproveViewModel.schoolApprove.books_in_library:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Drinking Water</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.drinking_water!= ""?schoolApproveViewModel.schoolApprove.drinking_water:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Ramps for Disabled</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.ramps_for_disable!= ""?schoolApproveViewModel.schoolApprove.ramps_for_disable:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Canteen</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.canteen!= ""?schoolApproveViewModel.schoolApprove.canteen:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Transport Facility</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.transport_facility!= ""?schoolApproveViewModel.schoolApprove.transport_facility:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">Computer Aided Learning Status</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.computer_aided_learning!= ""?schoolApproveViewModel.schoolApprove.computer_aided_learning:'-'}</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="fw-bold fs-14 ">If Available Number of Computers available</label><br></br>
                    <p className="text-dark">{schoolApproveViewModel.schoolApprove.computers!= 0?schoolApproveViewModel.schoolApprove.computers:'-'}</p>
                </div>
                
                <div className="col-md-12 text-end mb-2">
                <a className="btn btn-danger mb-2 mt-3 accept"   onClick={(e)=>schoolApproveViewModel.schoolApprovelStatusUpdate('Approved')}> Accept</a>
            <a className="btn btn-danger mb-2 mt-3 ms-2 reject"   onClick={(e)=>schoolApproveViewModel.schoolApprovelStatusUpdate('Rejected')}> Reject</a>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>

)



} 

export default SchoolApprove;