import { LoginViewModel } from "./LoginViewModel";

const  LoginView:React.FC=()=>
{
    const viewMode=LoginViewModel();

    return(
        <div className="auth-fluid">
         <div className="auth-fluid-form-box">
             <div className="card-body d-flex flex-column h-100 gap-3">

                 <div className="auth-brand text-center text-lg-start">
                     <a href="index.html" className="logo-dark">
                         <span><img src="assets/images/logo.png" alt="dark logo" height="80" /></span>
                     </a>
                     <a href="index.html" className="logo-light">
                         <span><img src="assets/images/logo.png" alt="logo" height="80" /></span>
                     </a>
                 </div>

                 <div className="my-auto">
                     <h4 className="mt-0">Sign In </h4>
                     <p className="text-muted mb-4">Enter your email address & password to access account. </p>

                     <form action="#">
                         <div className="mb-3">
                             <label className="form-label">Email address </label>
                             <input className="form-control" name="email" value={viewMode.loginDetails.email}  onChange={viewMode.handleChange} type="email" id="emailaddress" required placeholder="Enter your email" />
                             <div className="text-danger"> {(viewMode.loginDetails.email=='' && viewMode.submitted)?'Email':
                             
                            ( (viewMode.loginDetails.email!='' &&   viewMode.submitted && !viewMode.isEmailValid)?'Invalid Email':'')
                             
                              }</div>
                         
                         </div>
                         <div className="mb-3">
                             <a href="pages-recoverpw-2.html" className="text-muted float-end"><small>Forgot your password? </small></a>
                             <label className="form-label">Password </label>
                             <input className="form-control" name="password" value={viewMode.loginDetails.password}   type="password" onChange={viewMode.handleChange} required id="password" placeholder="Enter your password" />
                             <div className="text-danger"> {(viewMode.loginDetails.password=='' && viewMode.submitted)?'Password':'' }</div>
                         </div>
                         {/* <div className="mb-3">
                             <div className="form-check">
                                 <input type="checkbox" className="form-check-input" id="checkbox-signin" 
                                 checked={viewMode.rememberData===true}
                                 
                                 onChange={(e)=>viewMode.onRememberChange(e.target.checked)}/>
                                 <label className="form-check-label">Remember me </label>
                             </div>
                         </div> */}
                         <div className="d-grid mb-0 text-center">
                             <button className="btn btn-primary" type="submit" onClick={(e)=> viewMode.btnLoginClick(e)}><i className="mdi mdi-login"></i> Log In  </button>
                         </div>
                     </form>
                 </div>
                 <footer className="footer footer-alt">
                     <p className="text-muted">Don't have an account?  <a href="!#" className="text-muted ms-1"><b>Sign Up </b></a></p>
                 </footer>
             </div>
         </div>
         
         <div className="auth-fluid-right text-center">
             <div className="auth-user-testimonial">
                
             </div> 
         </div>
     </div>
    );
}
export default LoginView;