import React from "react";
import { Districtviewmodel } from "./DistrictViewModel";


const DistrictView:React.FC=()=>
 {

    const districtViewmodel = Districtviewmodel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">eCommerce </a></li> */}
                                        <li className="breadcrumb-item active">District </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">District </h4>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={districtViewmodel.searchTerm} onChange={districtViewmodel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={districtViewmodel.addState}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add District
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>District Code </th>
                                                            <th>District Name</th>
                                                            <th>State</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {districtViewmodel.filterDistricts.map((d: any, index: number) => (
                                                            <tr key={d.district_id}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.district_code}</td>
                                                                <td>{d.district_name}</td>
                                                                <td>{d.state_name}</td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => districtViewmodel.editDistrict(d)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => districtViewmodel.deleteDistrict(d.district_id)}></i>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={districtViewmodel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            districtViewmodel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${districtViewmodel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => districtViewmodel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={districtViewmodel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true"
                role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={districtViewmodel.saveDistrict}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="user-modalLabel">Add District</h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={districtViewmodel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">


                                    <div className="col-md-12 mb-2">
                                        <label>District Name</label>
                                        <input type="text" id="district_name" name="district_name" value={districtViewmodel.district.district_name} className="form-control" onChange={districtViewmodel.handleChange} />
                                        {<div className="text-danger">{districtViewmodel.validate.district_name}</div>}
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label >District Code</label>
                                        <input type="text" id="district_code" name="district_code" value={districtViewmodel.district.district_code} className="form-control" onChange={districtViewmodel.handleChange} />
                                        {<div className="text-danger">{districtViewmodel.validate.district_code}</div>}
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label >State</label>
                                        <select name="state_id" id="state_id" className="form-control" value={districtViewmodel.district.state_id} onChange={districtViewmodel.handleChange}>
                                            <option value="" selected aria-readonly>Select</option>
                                            {districtViewmodel.lstStates.data.map((d: any, index: number) => (
                                                <option value={d.state_id}>{d.state_name}</option>
                                            ))}
                                        </select>
                                        {<div className="text-danger">{districtViewmodel.validate.state}</div>}
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={districtViewmodel.cancel}>Close </button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );



}
export default DistrictView;