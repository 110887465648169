import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import Swal from "sweetalert2";


export function SchoolApproveviewModel(school_id:any)
{
    const dispatch = useDispatch();
   
    const schoolRepository=SchoolRepository();
    const imagePath=schoolRepository.imagePath;
    const [lstschoolApprove, setschoolApprove] = useState({
        data: []
    })
   

    const [schoolApprove, setSchoolApproves] = useState({
        school_id: 0,
        udise_code: '',
        school_name: '',
        instruction_medium: '',
        school_type: '',
        classes: '',
        year_of_establishment: 0,
        school_area: '',
        school_shifted_to_new_place: '',
        management: '',
        building: '',
        pre_primary_sectin_avilable: '',
        board_for_class_10th: '',
        status: '',
        image:'',
        //ADDRESS
        state_id: 0,
        district_id: 0,
        city_id: 0,
        cluster_id: 0,
        location: '',
        contact_no: '' ,
        city_name:'',
        state_name:'',
        district_name:'',
        cluster_name:'', 
        address:'',

        head_teacher: 0,
        total_teachers: 0,
        pre_primary_teachers: 0,
        female_teachers: 0,
        male_teachers: 0,
        contract_teachers: 0,
        primary_teachers: 0,
        middle_school_teachers: 0,
        high_school_teachers: 0 ,

        class_rooms: 0,
        boys_toilet: 0,
        girls_toilet: 0,
        mid_day_meal: '',
        is_school_residential: '',
        residential_type: '',
        electricity: '',
        wall: '',
        library: '',
        playground: '',
        books_in_library: 0,
        drinking_water: '',
        ramps_for_disable: '',
        canteen: '',
        transport_facility: '',
        computer_aided_learning: '',
        computers: 0,
       
    })
    // setSchoolApproves( data[0]);
    // dispatch({
    //     type: ActionType.STORE,
    //     payload: {
    //         storeData:{selected_school_id:0},
    //         viewName:'selectedSchool',
    //     },
    //   });


const schoolApprovelStatusUpdate=async (status:any)=>
{
    debugger;
    Swal.fire({
        title: 'Do you want to '+(status=='Approved'?'Approve':'Reject')+'?',
        text: "This action cannot be undone!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // User clicked "Yes, continue!" button
          const responseData:any = await  schoolRepository.schoolApprovelStatusUpdate({approved_status:status,school_id:school_id});

          if (responseData[0].db_result > 0) {
            Swal.fire(status+" successfully", status+" successfully", "success").then((action)=>
            {
                if (action.isConfirmed) {
                window.location.reload();
                }
            })
             ;
        
          }else{

          }


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked the cancel button
          Swal.fire('Cancelled', 'Your action has been cancelled.', 'info');
        }
      });

   

}


    const getSchoolApprove = async (school_id:number) => {

        debugger;
        try {
            const data:any = await  schoolRepository.getschoolApprove(school_id);

            setSchoolApproves( data[0]);
          
        
        }
        catch (error) {
            setschoolApprove({
                data: []
            })
        }
    } 
    const navigate = useHistory();
    // const userStoredData = useSelector((state: AppState) =>
    // {
    // //    / debugger; 
    //   return  state.storeData.viewName=='selectedSchool'?state.storeData.storeData:null;
    // });
    useEffect(() => {

        debugger;
        getSchoolApprove(school_id);
    }, [school_id]);

    
    return{schoolApprovelStatusUpdate,schoolApprove,imagePath}

}