import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository"

export function Stateviewmodel() {


    const schoolRepository = SchoolRepository();
    const [lstStates, setStates] = useState({
        data: []
    })

    const [lstFillterStates, setFillterStates] = useState(
        []

    )



    const [state, setState] = useState({
        state_id: 0,
        state_code: '',
        state_name: '',
        updated_date: ''
    })

    const [validate, setValidate] = useState({
        state_id: 0,
        state_code: '',
        state_name: '',
        updated_date: ''
    })

    const getStates = async () => {
        try {

            const data: any = await schoolRepository.getStates();
            setStates({
                data
            })

            setFillterStates(data);
        }
        catch (error) {
            setStates({
                data: []
            })
        }


    }


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredStates = lstStates.data.filter((state: any) =>
            state.state_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFillterStates(filteredStates);
        setSearchTerm(e.target.value);
    };



    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredStates = lstFillterStates.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFillterStates.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }



    useEffect(() => {
        debugger;
        getStates();
    }, []);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setState((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setState((prev) => ({ ...prev, [name]: value }));
        }
    }

    const editState = (e: any) => {
        debugger;
        setState(e);
    }

    const deleteState = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            const responseData: any = await schoolRepository.deleteState({ 'state_id': e });

            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getStates();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const addState = async (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
    }

    const saveState = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!state.state_name.trim()) {
            newValidate.state_name = "State name required" 
           setValidate(newValidate);
        }

      if (!state.state_code.trim()) {
            newValidate.state_code = "State code required" 
            setValidate(newValidate);
        }
        else {
            newValidate.state_name = "";
            newValidate.state_code ="";
            setValidate(newValidate);

        if (Object.values(newValidate).every((error) => !error)) {
            try {

                const responseData: any = await schoolRepository.saveState(state)

                if (responseData[0].db_result > 0) {
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }
                    getStates();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        }
    }
    }


    const cancel = () => {
        debugger;

        setState({
            state_id: 0,
            state_code: '',
            state_name: '',
            updated_date: ''
        })

        setValidate({
            state_id: 0,
            state_code: '',
            state_name: '',
            updated_date: ''
        })


    }






    return { searchTerm, handleSearch, addState, filteredStates, editState, deleteState, prePage, numbers, currentPage, changePage, nextPage, saveState, cancel, state, handleChange, validate, }


}