export interface AppState {
  storeData: {
    storeData: any | null 
    viewName: string;
  };
}

export enum ActionType {
  STORE = 'STORE',
}

export interface  StoreAction {
  type: ActionType.STORE;
  payload: {
    storeData: any | null 
    viewName: string;
  };
}

export type Action = StoreAction;