import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useHistory } from "react-router-dom";

export function SideMenuViewModel()
{
    const dispatch = useDispatch();
    const navigate = useHistory();
    const userStoredData = useSelector((state: AppState) =>
    {
    //    / debugger; 
      return  state.storeData.viewName=='AdminLogin'?state.storeData.storeData:null;
    });

    
    const [isMobileVisibilityHidden,setIsMobileVisibilityHidden]=useState(true);

    const [isVisibilityHidden,setIsVisibilityHidden]=useState(true);
    const [userName,setUserName]=useState('');

    const [role,setRole]=useState('');
const logoutClick=()=>{

   
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:{},
            viewName:'AdminLogin',
        },
      });

      navigate.push('/login');
}
const sidenavmobileFunction=()=>
{
    debugger;
    setIsMobileVisibilityHidden(isMobileVisibilityHidden?false:true);
}

    const sidenavFunction=()=>
    {


       
        debugger;
        let sidenav:any=document.getElementById("mySidenav");
        if(sidenav.style.width=="250px")

        {
            sidenav.style.width = "";
            setIsVisibilityHidden(true);
        }else{
            sidenav.style.width = "250px";
            setIsVisibilityHidden(false);

        }

    }
    useEffect(()=>{
        const loginCheck=()=>
        {

           

        debugger;
        try{
            if(typeof (userStoredData.user_id)=='undefined' || (+userStoredData.user_id==0))
            {
                navigate.push('/login');
            } else{


                setUserName(userStoredData.full_name);

                setRole(userStoredData.role)
            }

        }catch(e)
        {
            navigate.push('/login');
        }
        
        }
        
        
        
        loginCheck();
        
        });

    return{isMobileVisibilityHidden,sidenavmobileFunction,isVisibilityHidden,sidenavFunction,logoutClick,role,userName}
}