import { CityViewModel } from "../City/CityViewModel";

const CityView:React.FC=()=>
 {

    const cityViewModel = CityViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">EIT </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">ECommerce </a></li> */}
                                        <li className="breadcrumb-item active">City </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">City </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={cityViewModel.searchTerm} onChange={cityViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={cityViewModel.addCity}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add City
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>City Code </th>
                                                            <th>City Name</th>
                                                            <th>District</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cityViewModel.filteredCitys.map((d: any, index: number) => (
                                                            <tr key={d.city_code}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.city_code}</td>

                                                                <td>{d.city_name}</td>
                                                                <td>{d.district_name}</td>

                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => cityViewModel.editCity(d)}></i>
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => cityViewModel.deleteCitys(d.city_id)}></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>

                                                </table>

                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={cityViewModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            cityViewModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${cityViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => cityViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={cityViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={cityViewModel.saveCity}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add City </h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={cityViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label >City Code</label>
                                        <input type="text" id="city_code" name="city_code" value={cityViewModel.city.city_code} className="form-control" onChange={cityViewModel.handleChange} />
                                        {<div className="text-danger">{cityViewModel.validate.city_code}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >City Name</label>
                                        <input type="text" id="city_name" name="city_name" value={cityViewModel.city.city_name} className="form-control" onChange={cityViewModel.handleChange} />
                                        {<div className="text-danger">{cityViewModel.validate.city_name}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >District Name</label>
                                        <select name="district_id" id="district_id" className="form-control" value={cityViewModel.city.district_id} onChange={cityViewModel.handleChange}>
                                            <option value="" selected aria-readonly>Select</option>
                                            {cityViewModel.lstDistricts.data.map((d: any, index: number) => (
                                                <option value={d.district_id}>{d.district_name}</option>
                                            ))}
                                        </select>
                                        {<div className="text-danger">{cityViewModel.validate.district_name}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={cityViewModel.cancel} >Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default CityView;